.tombol_save:hover {
    background-color: aquamarine;
}

.check-btn {
    opacity: 0;
    position: relative;
    top: 2px;
  }

  .Embed_container{
    width: 500px;
    height: 1000px;
  }
  
.label-custom {
    margin: 0px 2px;
    border-radius: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    width: 100%;
    transition: 0.3s ease;
    background: #f5f7fb;
    border: 0.794239px solid #4d5b9e;
    box-sizing: border-box;
    border-radius: 7.94239px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 5px 10px 5px 10px;
  }
  
.label-custom:hover {
    background-color: #d6dbf5;
    cursor: pointer;
  }
  
  .text-inner {
    position: relative;
    right: 10px;
  }


  

  

  

  