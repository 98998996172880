.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px; 
    height: 20px; 
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  
  .switch:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px; 
    left: 2px; 
    bottom: 2px; 
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  input:checked + .switch:before {
    transform: translateX(20px);
  }
  
  input:checked + .switch {
    background-color: #2196f3;
  }
 
  .switch:before {
    transition: transform 0.3s, background-color 0.3s;
  }
  
  input:checked + .switch:before {
    transition: transform 0.3s, background-color 0.3s;
  }
  