.profile-container {
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-card {
  background-color: #4e4e4e;
  padding: 20px;
  border: none;
  border-radius: 10px;
  width: 60%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-data {
  display: flex;
  padding-left: 30px;
  margin-bottom: 15px;
}

.profile-details {
  padding-left: 10px;
}

.profile-details span {
  display: block;
}

.profile-icon {
  font-size: 35px;
  width: 45px;
}

.profile-desc {
  display: block;
  min-width: 50%;
  max-width: 100%;
  min-height: 50vh;
  /* background-color: red; */
}

.profile-img {
  display: block;
  min-width: 50%;
  max-width: 100%;
  min-height: 50vh;
  /* background-color: blue; */
}

.profile-avatar {
  padding-top: 20px;
  position: relative;
  float: right;
  max-width: 100%;
}

.profile-button {
  width: 100%;
  height: 60px;
  margin-top: 15px;
  font-size: 1.3rem;
  background: #e50914;
  border: 1px solid #e50914;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.profile-button:hover {
  background: #8b040b;
  border: 1px solid #8b040b;
}

.Square {
  transition-delay: 10s;
}

.button-treatment-plan{
background-color: #00d4cd;
}

.button-treatment-plan:hover{
background-color: #a1a1a1;
}

.button-treatment-plan-add{
background-color: #667BFF;
}

.button-treatment-plan-add:hover{
background-color: #bdbdbd;
}

.button-treatment-plan-reset{
background-color: #FF6666;
}

.button-treatment-plan-reset:hover{
background-color: #bdbdbd;
}

.focused:focus{
outline: 3px solid rgb(162, 230, 255)
}

.footer {
  page-break-before: always;
  bottom: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Atur warna latar belakang sesuai kebutuhan */
  color: #000; /* Atur warna teks sesuai kebutuhan */
  text-align: center;
  padding: 5px;
  border-top: 1px solid #000; /* Atur garis pemisah antara konten dan footer sesuai kebutuhan */
}

.datePickerInveontoryIn {
border-radius: 5px;
outline: none;
border: 1px solid rgb(198, 198, 198);
text-align: center;
width: 200px !important;
height: 40px;
cursor: pointer;
padding-left: 0px; 
background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
background-size: 20px;
background-repeat: no-repeat;
background-position: 170px center; 
}

.datePickerInveontoryOut {
border-radius: 5px;
outline: none;
border: 1px solid rgb(198, 198, 198);
text-align: center;
width: 200px !important;
height: 40px;
cursor: pointer;
padding-left: 0px; 
background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
background-size: 20px;
background-repeat: no-repeat;
background-position: 170px center; 
}

@media print {
.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}
}

.my-svg {
border: 1px solid black;
border-bottom: 2px solid black;
box-shadow: inset 0 0 0 5px black !important;
}

.element {
line-height: 0.1;
}

.dropdown-container {
position: relative;
display: inline-block;
}

.dropdown-menu {
display: none;
position: absolute;
top: 100%;
left: 0;
width: 40px;
background-color: #fff;
list-style-type: none;
padding: 0;
margin: 0;
}

.dropdown-menu li {
transition: background-color 0.3s ease;
}

.dropdown-menu li a{
text-decoration: none;
}

.dropdown-menu li:hover {
background-color: #f0f0f0; /* Change the background color on hover */
}

.dropdown-menu li ul li{
text-align: center;
}

.dropdown-container:hover .dropdown-menu {
display: block;
}

.submenu:hover .sub-menu {
display: block;
}

.sub-menu li {
display: block;
}

.submenu{
text-align: center;
}

.sub-menu{
display: none;
text-align: center;
position: absolute;
top: 0;
width: 70px;
left: 100%;
background-color: #fff;
box-shadow: 0px 8px 16px 0px rgba(161, 161, 161, 0.2);
list-style-type: none;
padding: 0;
margin: 0;
}

::-webkit-datetime-edit-fields-wrapper { cursor: pointer;}

::-webkit-calendar-picker-indicator { 
 cursor: pointer;
}

input[type=checkbox]
{

transform: scale(1.5);
padding: 20px;
}

.Embed_container{
  width: 500px;
  height: 1000px;
}

.label-custom {
  margin: 0px 2px;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  transition: 0.3s ease;
  background: #f5f7fb;
  border: 0.794239px solid #4d5b9e;
  box-sizing: border-box;
  border-radius: 7.94239px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px 10px 5px 10px;
}

.label-custom:hover {
  background-color: #d6dbf5;
  cursor: pointer;
}

.text-inner {
  position: relative;
  right: 10px;
}

input::placeholder {
  padding-left: 5px;
  font-size: 14px;
  color: #999999;
  font-family:'Times New Roman', Times, serif
}

.select-name-tag {
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
}

.tombol_save:hover {
  background-color: aquamarine;
}

.check-btn {
  opacity: 0;
  position: relative;
  top: 2px;
}

.label-custom{
width: 70px;
height: 45px;
display: flex;
justify-content: center;
align-items: center;
margin: 0px 2px;
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 15px;
transition: 0.3s ease;
background: #f5f7fb;
border: 0.794239px solid #4d5b9e;
box-sizing: border-box;
}

.label-custom:hover {
  background-color: #d6dbf5;
  cursor: pointer;
}

.text-inner {
  position: relative;
  right: 8px;
}
  
.label-custom {
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
  border-radius: 1px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  border-radius: 3px;
  transition: 0.3s ease;
  background: #f5f7fb;
  border: 0.794239px solid #4d5b9e;
  box-sizing: border-box;
}
  
.label-custom:hover {
  background-color: #d6dbf5;
  cursor: pointer;
}
  
.text-inner {
  position: relative;
  right: 8px;
}

.datePicker {
   border-radius: 5px;
   outline: none;
   border: 1px solid rgb(198, 198, 198);
   text-align: center;
   width: 200px !important;
   height: 40px;
   cursor: pointer;
   padding-left: 0px; 
   background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
   background-size: 20px;
   background-repeat: no-repeat;
   background-position: 170px center; 
  }

  .selectPicker {
    padding-left: 0px;
    background-image: url("https://cdn-icons-png.flaticon.com/128/896/896442.png"); 
    background-size: 20px; 
    background-repeat: no-repeat;
    background-position: 170px center; 
   }

   .datePickerMobile {
    border-radius: 5px;
    outline: none;
    border: 1px solid rgb(198, 198, 198);
    text-align: center;
    width: 105%;
    height: 40px;
    cursor: pointer;
    padding-left: 0px; 
    background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 10px center; 
    cursor: pointer;
   }
 
   .selectPickerMobile {
     padding-left: 0px;
     background-image: url("https://cdn-icons-png.flaticon.com/128/896/896442.png"); 
     background-size: 19px; 
     background-repeat: no-repeat;
     background-position: 110px center; 
    }

/* DropdownBotton */

.datePickerDropdownBotton {
  margin-right: 5px;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(198, 198, 198);
  text-align: center;
  height: 40px;
  cursor: pointer;
  padding-left: 0px; 
  background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 160px center; 
 }

 .selectPickerDropdownBotton {
   padding-left: 0px;
   background-image: url("https://cdn-icons-png.flaticon.com/128/896/896442.png"); 
   background-size: 20px; 
   background-repeat: no-repeat;
   background-position: 170px center; 
  }

  .datePickerMobileDropdownBotton {
   border-radius: 5px;
   outline: none;
   border: 1px solid rgb(198, 198, 198);
   text-align: center;
   width: 105%;
   height: 40px;
   cursor: pointer;
   padding-left: 0px; 
   background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
   background-size: 17px;
   background-repeat: no-repeat;
   background-position: 10px center; 
   cursor: pointer;
  }

  .selectPickerMobileDropdownBotton {
    padding-left: 0px;
    background-image: url("https://cdn-icons-png.flaticon.com/128/896/896442.png"); 
    background-size: 19px; 
    background-repeat: no-repeat;
    background-position: 130px center; 
   }

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.square{
  transition: all 1s;
  cursor: pointer;
  flex: 50%;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height:150px;
  font-size: 25px; 
  background-color: linear-gradient(red, yellow); 
  color: white;
  margin-right: 5px; 
  border-radius:5px
}

.square:hover{
  background-color: rgb(178, 42, 42);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0.0;
    font-size: 40px;
    padding-right: 0px;
    position: relative;
}

input[type="date"] {
    outline: none; /* Hapus outline bawaan */
}

input[type="date"]::before {
    content: '\2022'; /* Karakter titik sebagai gantinya */
    position: absolute;
    top: 50%;
    right: 8px; /* Sesuaikan jarak outline dengan kanan */
    transform: translateY(-50%);
    color: #808080; /* Warna abu-abu */
}


input[type="date"].expiredDateInventory::-webkit-inner-spin-button,
input[type="date"].expiredDateInventory::-webkit-calendar-picker-indicator {
  opacity: 5;
  font-size: 20px;
  padding-right: 0px;
  position: relative;
  padding-right: 10px;
  background-image: url("https://cdn-icons-png.flaticon.com/128/2370/2370264.png");
}

.botton_delete{
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid red;
  background-color:red; 
  color: white;
}

.botton_delete:hover{
  border: 1px solid #808080;
  background-color:#808080;
}

.mapping-schedule{
  width: 100px;
  border: 2px solid #29B8FF;
  text-align: center;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.mapping-schedule:hover{
  width: 100px;
  border: 2px solid #29B8FF;
  text-align: center;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  background-color:aqua;
}




































