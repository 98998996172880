.tagDashboard{
    border-bottom: 3px solid rgb(255, 255, 255);
    cursor: pointer;
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.tagDashboard:hover{
    border-bottom: 3px solid rgb(0, 143, 191);
    cursor: pointer;
}

.tagAppointment{
  font-size: 15px;
  color: rgb(129, 129, 129);
  border-bottom: 3px solid rgb(255, 255, 255);
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.tagAppointmentBorder{
  font-size: 15px;
  color: rgb(129, 129, 129);
  border-bottom: 3px solid rgb(66, 135, 255);
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.tagAppointment:hover{
  cursor: pointer;
  font-size: 15px;
  border-bottom: 3px solid rgb(0, 143, 191);
  cursor: pointer;
}

.signOut{
  color: white;
}

.signOut:hover{
   color: antiquewhite;
}


.pagination-lin{
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(122, 195, 255) !important;
  color: rgb(94, 94, 94) !important;
}

 
.pagination-lin:hover{
  background-color: rgb(225, 242, 255) !important;
  border: 1px solid rgb(122, 195, 255) !important;
  color: rgb(94, 94, 94) !important;
}

