/* #header {
    position: absolute;
    width: 220px;
  }
  #header .pro-sidebar {
    height: 100vh;
  }
  #header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color: white;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #fbf4cd;
    color: #000;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
  }
  #header .logo {
    padding: 20px;
  }
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  } */

  .sidebar {
    background-color: rgb(255, 255, 255) !important;
    transition: 2s;
    transition: transform .3s ease-out;
  }
/* 
  .submenu {
    background-color: rgb(255, 255, 255) !important;
    transition: 2s;
    transition: transform .3s ease-out;
    margin-top: 20px;
    font-family: sans-serif;
    padding-left: 30px;
    display: flex;
    alignItems: center, 
    height:"5vh", 
    borderTop:"1px solid #D5D5D5", 
    borderBottom:"1px solid #D5D5D5", 
    fontWeight:"bold",color:"#666666", 
    fontSize:"14px"
  } */

  .sidebar:hover {
    width: 250px;
    background-color: rgb(255, 255, 255) !important;
    transition-property: width;
    transition-duration: 1s;
    transition-delay: 0.5s;
  }

  #about1:hover {
    /* overflow: hidden; */
    /* padding-right: 200px; */
    /* display: flex;
    justify-content: start;
    align-content: left; */
    
    width: 100%;
    text-align: left;
    align-items: left;
    transition-property: padding;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
    /* overflow: hidden;
    visibility: hidden;
    display: none; */
  }

  #about1 {

    width: 100%;
    transition-property: width;
    transition-duration: 1s;
    transition-delay: 0.5s;
  }

  /* #aboutspan{
    
  }

  
  #aboutspan:hover{
    overflow: hidden;
    visibility: hidden;
    display: none;
  } */

  /* 

  */

/*
  #about2 {
    top: 30px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about3 {
    top: 60px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about4 {
    top: 90px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about5 {
    top: 120px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about6 {
    top: 150px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about7 {
    top: 1800px;
    background-color: #1e00ff;
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }

  #about{
    position: absolute;
    left: -80px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }
  
  

  #about2:hover {
    left: 0;
  }
  #about3:hover {
    left: 0;
  }  
  #about4:hover {
    left: 0;
  }  
  #about5:hover {
    left: 0;
  }  
  #about6:hover {
    left: 0;
  }  
  #about7:hover {
    left: 0;
  }   */
